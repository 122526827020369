const React = require('react');
const { string, shape } = require('prop-types');
const Script = require('nordic/script');
const classNames = require('classnames');
const MelidataTrack = require('nordic/melidata/melidata-track');

const AdvertisingAdn = ({ content }) => {
  const { queries } = content || {};
  const { platform, slot_id, placement, page, site_id, category_id } = queries || {};
  const className = platform === 'desktop' ? 'ui-billboard-ad' : 'ui-fullscreen-ad-pdp loaded';
  const style = slot_id ? slot_id.includes('motors') || slot_id.includes('state') : null;

  const eventData = {
    status: 'error',
    reason: `vpp params missing: platform: ${platform},siteId: ${site_id},category_id: ${category_id}`,
    source: 'script',
    params: {
      placement,
      page,
      slot_id,
    },
  };

  return (
    <div id={className} className={classNames('container-advertising', className, { placement: style })}>
      {placement && page && slot_id && platform && site_id ? (
        <>
          <Script src="https://http2.mlstatic.com/frontend-assets/adn-frontend-library/adn-script.js" />
          <div data-component="adn" data-content={JSON.stringify(content)} />
        </>
      ) : (
        <MelidataTrack path="/advertising/adn" type="event" event_data={eventData} />
      )}
    </div>
  );
};

AdvertisingAdn.propTypes = {
  content: shape({
    category: string,
    page: string,
    placement: string,
    platform: string,
    site_id: string,
    slot_id: string,
    title: string,
  }).isRequired,
};

module.exports = AdvertisingAdn;
